import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styled from 'styled-components'
import Icon from '@objects/icon'

const StyledDropdown = styled.div`
  position: relative;
  .toggle {
    background-color: ${({ theme }) => theme.colors.blue.default};
    border-radius: 20px;
    width: 100%;
    color: #fff;
    padding: ${({ theme }) =>
      `6px ${theme.spacing['5']} ${theme.spacing['0.5']} ${theme.spacing['7']}`};
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    &.is-open {
      border-radius: 20px 20px 0 0;
    }
    &:focus {
      outline: 1px dotted #000;
    }
  }

  .content {
    position: absolute;
    width: 100%;
    z-index: 1;
    color: #fff;
    padding: ${({ theme }) => `${theme.spacing['5']} ${theme.spacing['7']}`};
    background-color: ${({ theme }) => theme.colors.blue[650]};
    border-radius: 0 0 20px 20px;
  }
`

function Dropdown({ className, label, children, multiselect }) {
  const [isOpen, setIsOpen] = useState(false)
  const CompRef = useRef(null)

  const toggleId = `toggle-${label.toLowerCase().replace(/ /g, '')}`
  const contentId = `content-${label.toLowerCase().replace(/ /g, '')}`

  function onClick() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    function onClickOutside(event) {
      if (CompRef.current && !CompRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', onClickOutside)
    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  return (
    <StyledDropdown ref={CompRef}>
      <button
        id={toggleId}
        className={clsx('toggle', { 'is-open': isOpen })}
        onClick={onClick}
        aria-controls={contentId}
        aria-expanded={isOpen}
      >
        {label}
        <Icon
          name={isOpen ? 'ToggleOpen' : 'ToggleClose'}
          className="text-xl"
        />
      </button>
      <div
        id={contentId}
        role={multiselect ? 'listbox' : 'region'}
        className={clsx('content', {
          hidden: !isOpen,
        })}
        aria-multiselectable={multiselect ? true : null}
        aria-labelledby={toggleId}
        aria-controls={multiselect}
      >
        {children}
      </div>
    </StyledDropdown>
  )
}

Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
  multiselect: PropTypes.string,
}

export default Dropdown
